import { InertiaProgress } from "@inertiajs/progress";
import Vue from "vue";

window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

InertiaProgress.init({
    delay: 100,
    color: "#003F54",
    includeCSS: true,
    showSpinner: false,
});

import { Link } from "@inertiajs/inertia-vue";
import ArrowUpIcon from "./Icons/ArrowUpIcon";
import CertificateIcon from "./Icons/CertificateIcon";
import ApplicationStep from "./Pages/Wizards/Application/ApplicationStep";
import EducationIcon from "./Icons/EducationIcon";
import IdeaIcon from "./Icons/IdeaIcon";
import InboxIcon from "./Icons/InboxIcon";
import InternshipIcon from "./Icons/InternshipIcon";
import JobIcon from "./Icons/JobIcon";
import AdLink from "./Components/AdLink";
import AdList from "./Components/AdList";
import Ad from "./Components/Ad";
import Card from "./Components/Card";
import Categories from "./Components/Categories";
import CategoryDropdown from "./Components/CategoryDropdown";
import CTA from "./Components/CTA";
import DateTime from "./Components/DateTime";
import Footer from "./Components/Footer";
import FormProgress from "./Components/FormProgress";
import Hero from "./Components/Hero";
import PageTop from "./Components/PageTop";
import Projects from "./Components/Projects";
import Team from "./Components/Team";
import HomeLayout from "./HomeLayout";
import Layout from "./Layout";

Vue.component("Link", Link);
Vue.component("HomeLayout", HomeLayout);
Vue.component("Layout", Layout);
Vue.component("ArrowUpIcon", ArrowUpIcon);
Vue.component("ApplicationStep", ApplicationStep);
Vue.component("CertificateIcon", CertificateIcon);
Vue.component("EducationIcon", EducationIcon);
Vue.component("IdeaIcon", IdeaIcon);
Vue.component("InboxIcon", InboxIcon);
Vue.component("InternshipIcon", InternshipIcon);
Vue.component("JobIcon", JobIcon);
Vue.component("AdLink", AdLink);
Vue.component("AdList", AdList);
Vue.component("Ad", Ad);
Vue.component("Card", Card);
Vue.component("Categories", Categories);
Vue.component("CategoryDropdown", CategoryDropdown);
Vue.component("CTA", CTA);
Vue.component("DateTime", DateTime);
Vue.component("Footer", Footer);
Vue.component("FormProgress", FormProgress);
Vue.component("Hero", Hero);
Vue.component("PageTop", PageTop);
Vue.component("Projects", Projects);
Vue.component("Team", Team);
