<template>
    <div class="bg-white pt-16 lg:py-24">
        <div
            class="
                pb-16
                bg-gradient-to-l
                from-blue-700
                to-blue-500
                lg:pb-0 lg:z-10 lg:relative
            "
        >
            <div
                class="
                    lg:mx-auto
                    lg:max-w-7xl
                    lg:px-8
                    lg:grid
                    lg:grid-cols-2
                    lg:gap-8
                    lg:h-[25vw]
                    xl:h-[15vw]
                "
            >
                <div class="relative lg:-my-8">
                    <div
                        aria-hidden="true"
                        class="
                            absolute
                            inset-x-0
                            top-0
                            h-1/2
                            bg-white
                            lg:hidden
                        "
                    />
                    <flickity
                        ref="flickity"
                        :options="flickityOptions"
                        @init="onInit"
                        class="
                            max-w-md
                            mx-4
                            md:mx-auto
                            sm:max-w-3xl
                            lg:p-0 lg:h-full
                            rounded-xl
                            shadow-xl
                            overflow-hidden
                        "
                    >
                        <div
                            v-for="project in projects"
                            class="carousel-cell w-full sm:h-[30vh] lg:h-full"
                        >
                            <div
                                class="
                                    aspect-w-10 aspect-h-6
                                    sm:aspect-w-16 sm:aspect-h-7
                                    lg:aspect-none lg:h-full
                                "
                            >
                                <img
                                    class="object-cover lg:h-full lg:w-full"
                                    :src="project.imageUrl"
                                    alt=""
                                />
                            </div>
                        </div>
                    </flickity>
                </div>
                <div
                    class="mt-6 lg:m-0 lg:pl-8 flex justify-center items-center"
                >
                    <div
                        class="
                            mx-auto
                            max-w-md
                            px-4
                            sm:max-w-2xl sm:px-6
                            lg:px-0 lg:max-w-none
                        "
                    >
                        <div>
                            <transition name="fade" mode="out-in">
                                <p
                                    v-bind:key="selectedIndex"
                                    class="mt-2 text-2xl font-medium text-white"
                                >
                                    {{ projects[selectedIndex].title }}
                                </p>
                            </transition>
                        </div>
                        <footer class="mt-8 max-w-prose">
                            <transition name="fade" mode="out-in">
                                <p
                                    v-bind:key="selectedIndex"
                                    class="text-base font-medium text-white"
                                >
                                    {{ projects[selectedIndex].description }}
                                </p>
                            </transition>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Flickity from "vue-flickity";

const projects = [
    {
        title: "TPW Centrum für Luft- und Raumfahrt",
        description:
            "Das Warnemünder Centrum für Luft- und Raumfahrt: Ein Großprojekt – alle Leistungen durch Warbau (inklusive Kauf, Konzeptentwicklung und Fachplanung)",
        imageUrl: "/img/project1-c.jpg",
    },
    {
        title: "Wohn- und Geschäftshaus in der Rostocker Innenstadt",
        description:
            "Als Generalunternehmer übernahm die Warnemünder Bau GmbH hier die Bauausführung und erstellte den Rohbau in klassischer Bauweise in Kalksandstein.",
        imageUrl: "/img/project2-c.jpg",
    },
    {
        title: "Komplexe Innensanierung eines Einfamilienhauses",
        description:
            "Die Modernisierungsleistungen umfassten die komplette Sanitär- und Heizungsinstallationen inkl. Fußbodenheizung, Elektroinstallationen und Fliesenausstattung sowie Mauer-, Beton- und Putzarbeiten.",
        imageUrl: "/img/project3-c.jpg",
    },
];

export default {
    components: { Flickity },
    data() {
        return {
            selectedIndex: 0,
            projects,
            flickityOptions: {
                prevNextButtons: true,
                pageDots: false,
                wrapAround: true,
                adaptiveHeight: false,
            },
        };
    },
    methods: {
        onInit() {
            this.$refs.flickity.on("change", (e) => {
                this.selectedIndex = e;
            });
        },
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
