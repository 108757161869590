<template>
    <Layout>
        <Head>
            <title>Erfolg der Bewerbung | Warnemünder Bau GmbH</title>
        </Head>

        <Card>
            <div class="px-4 md:px-8 py-8 md:py-32 space-y-8 text-center">
                <div class="w-32 h-32 mx-auto mb-6">
                    <inbox-icon />
                </div>
                <h2
                    class="
                        text-3xl text-blue-600
                        font-extrabold
                        tracking-tight
                        sm:text-4xl
                    "
                >
                    Vielen Dank für Ihre Bewerbung!
                </h2>
                <p class="max-w-prose mx-auto text-lg">
                    So geht es nun weiter: Unsere Personalleiterin sichtet Ihre
                    Bewerbung. Interessante Bewerbungen werden der
                    Geschäftsführung vorgeschlagen. Wenn alles passt, laden wir
                    Sie anschließend zu einem Gespräch nach Warnemünde ein.
                </p>
            </div>
        </Card>
    </Layout>
</template>

<script>
export default {};
</script>
