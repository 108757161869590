<template>
    <div
        class="
            lg:backdrop-filter lg:backdrop-blur-lg lg:shadow
            sm:rounded-md
            z-20
            relative
            backdrop-filter-fallback
        "
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Card",
};
</script>
