export default {
    props: {
        arcanist: {
            type: Object,
        },
        errors: {
            type: Object,
        },
        wide: {
            type: Boolean,
            default: false,
        },
        submitLabel: {
            type: String,
            default: "Weiter",
        },
    },
    data() {
        return {
            schema: this.arcanist.step.schema,
            values: this.getInitialValues(),
        };
    },
    computed: {
        steps() {
            return this.arcanist.wizard.steps;
        },
        currentStep() {
            return this.steps.find((step) => step.active) || null;
        },
        currentStepIndex() {
            if (this.currentStep === null) {
                return null;
            }
            return this.steps.indexOf(this.currentStep);
        },
        previousStepUrl() {
            if (this.currentStepIndex === null) {
                return null;
            }
            if (this.currentStepIndex === 1) {
                return null;
            }
            return this.steps[this.currentStepIndex - 1].url;
        },
    },
    methods: {
        onSubmit(data) {
            this.$inertia.post(window.location, data);
        },
        getInitialValues() {
            const { data, ad, schema, ...values } = this.arcanist.step;
            return values;
        },
        onFileRemoved(payload) {
            console.log("removed", payload);
        },
    },
};
