<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="relative bg-white py-16 sm:py-24 lg:py-32">
        <div
            class="
                mx-auto
                max-w-md
                px-4
                text-center
                sm:max-w-3xl sm:px-6
                lg:px-8 lg:max-w-7xl
            "
        >
            <h2
                class="
                    text-base
                    font-semibold
                    tracking-wider
                    text-blue-600
                    uppercase
                "
            >
                Warnemünder Bau
            </h2>
            <p class="mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl">
                Die Menschen sind das wichtigste Gut eines jeden Unternehmens
            </p>
            <p class="mt-5 max-w-prose mx-auto text-xl text-blue-800">
                2008 erhielt die Warnemünder Bau GmbH eine Auszeichnung der
                Hansestadt Rostock, die das Engagement bei der Schaffung von
                Ausbildungsplätzen und Berufseinstiegsmöglichkeiten für
                Jugendliche würdigt.
            </p>
            <div class="mt-12">
                <div
                    class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3"
                >
                    <Link
                        :href="category.route"
                        v-for="category in categories"
                        :key="category.slug"
                        class="pt-6 block group"
                    >
                        <div
                            class="
                                bg-gray-50
                                transition
                                group-focus:bg-blue-50
                                group-hover:bg-blue-50
                                rounded-lg
                                px-6
                                pb-8
                                h-full
                                flex flex-col
                                justify-between
                            "
                        >
                            <div class="-mt-6">
                                <div>
                                    <span
                                        class="
                                            inline-flex
                                            items-center
                                            justify-center
                                            p-3
                                            bg-white
                                            border border-blue-50
                                            rounded-md
                                            shadow-lg
                                            transform
                                            transition
                                            group-focus:-translate-y-2
                                            group-hover:-translate-y-2
                                        "
                                    >
                                        <component
                                            :is="category.icon"
                                            class="h-12 w-12 text-white"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </div>
                                <h3
                                    class="
                                        mt-8
                                        text-lg
                                        font-medium
                                        tracking-tight
                                    "
                                >
                                    {{ category.name }}
                                </h3>
                                <p class="mt-5 text-base text-blue-800">
                                    {{ category.shortDescription }}
                                </p>
                            </div>

                            <p class="mt-10 text-base text-blue-600">
                                Zu den Stellen ›
                            </p>
                        </div>
                    </Link>
                </div>
            </div>
            <div class="mt-12 sm:hidden">
                <a
                    :href="$route('jobs')"
                    class="
                        rounded
                        shadow
                        mx-auto
                        inline-block
                        px-8
                        py-3
                        border border-transparent
                        text-base
                        font-medium
                        rounded-md
                        text-white
                        bg-blue-600
                        hover:bg-blue-700
                        md:py-4 md:text-lg md:px-10
                    "
                >
                    Alle Jobs
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        categories: {
            type: Array,
        },
    },
};
</script>
