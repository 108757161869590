import "./bootstrap";
import "./formulate";

import Vue from "vue";

Vue.prototype.$route = route;
import { createInertiaApp } from "@inertiajs/inertia-vue";

createInertiaApp({
    resolve: (name) => require(`./Pages/${name}`),
    setup({ el, App, props }) {
        new Vue({
            render: (h) => h(App, props),
        }).$mount(el);
    },
});
