<template>
    <div
        class="
            bg-white
            min-h-full
            px-4
            py-16
            sm:px-6 sm:py-24
            md:grid md:place-items-center
            lg:px-8
        "
    >
        <div class="max-w-max mx-auto">
            <main class="sm:flex">
                <p class="text-4xl font-bold text-blue-600 sm:text-5xl">
                    {{ status }}
                </p>
                <div class="sm:ml-6">
                    <div class="sm:border-l sm:border-gray-200 sm:pl-6">
                        <h1
                            class="
                                text-4xl
                                font-extrabold
                                text-gray-900
                                tracking-tight
                                sm:text-5xl
                            "
                        >
                            {{ title }}
                        </h1>
                        <p class="mt-1 text-lg text-gray-500">
                            {{ description }}
                        </p>
                    </div>
                    <div
                        class="
                            mt-10
                            flex
                            space-x-3
                            sm:border-l sm:border-transparent sm:pl-6
                        "
                    >
                        <Link href="/" class="btn">
                            Zurück zur Startseite
                        </Link>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        status: Number,
    },
    computed: {
        title() {
            return {
                503: "Dienst nicht erreichbar",
                500: "Server-Fehler",
                404: "Seite nicht gefunden",
                403: "Zugang verboten",
            }[this.status];
        },
        description() {
            return {
                503: "Entschuldigen Sie, unsere Seite ist im Wartungsmodus. Wir sind in Kürze wieder erreichbar.",
                500: "Ups, auf dem Server ist etwas schief gelaufen.",
                404: "Die Seite konnte nicht gefunden werden.",
                403: "Sie haben nicht die nötige Berechtigung für diese Aktion.",
            }[this.status];
        },
    },
};
</script>
