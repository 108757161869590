<template>
    <svg
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        v-if="small"
    >
        <path
            fill="none"
            stroke="currentColor"
            stroke-miterlimit="10"
            d="M11.2,2.5H8.7c-0.7,0-1.3,0.6-1.3,1.3v0.5h5.4V4 C12.7,3.2,12,2.5,11.2,2.5z"
        />
        <rect
            x="2.3"
            y="4.4"
            fill="none"
            stroke="currentColor"
            stroke-miterlimit="10"
            width="15.4"
            height="13"
        />
        <path
            fill="none"
            stroke="currentColor"
            stroke-miterlimit="10"
            d="M13.3,11.5H6.7c-2.4,0-4.4-2-4.4-4.4V4.4h15.4v2.7 C17.7,9.5,15.8,11.5,13.3,11.5z"
        />
        <circle fill="currentColor" cx="10" cy="8.7" r="0.8" />
    </svg>
    <svg
        viewBox="0 0 48 48"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        v-else
    >
        <path
            d="M30.3 3.2c.7 0 1.3.6 1.3 1.3v3.8H16.4V4.4c0-.7.6-1.3 1.3-1.3h12.6m0-1.2H17.7c-1.4 0-2.5 1.1-2.5 2.5v5.1h17.7V4.4c-.1-1.4-1.2-2.5-2.6-2.5z"
            fill="currentColor"
            class="text-blue-600"
        />
        <path
            d="M.6 20.2h46.7v25.3H.6V20.2z"
            fill="currentColor"
            class="text-blue-100"
        />
        <path
            d="M46.7 20.8v24H1.3v-24h45.4m1.3-1.2H0v26.5h48V19.6z"
            fill="currentColor"
            class="text-blue-600"
        />
        <path
            d="M5.1 27.8c-2.4 0-4.4-2-4.4-4.4V6.3h46.7v17.1c0 2.4-2 4.4-4.4 4.4H5.1z"
            fill="currentColor"
            class="text-blue-100"
        />
        <path
            d="M46.7 6.9v16.4c0 2.1-1.7 3.8-3.8 3.8H5.1c-2.1 0-3.8-1.7-3.8-3.8V6.9h45.4M48 5.7H0v17.7c0 2.8 2.3 5.1 5.1 5.1H43c2.8 0 5.1-2.3 5.1-5.1V5.7H48z"
            fill="currentColor"
            class="text-blue-600"
        />
        <path
            d="M24 19.6c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zM34.1 6.9h-3.8v-.6c0-1 .8-1.9 1.9-1.9 1 0 1.9.8 1.9 1.9v.6zm-16.4 0h-3.8v-.6c0-1 .8-1.9 1.9-1.9 1 0 1.9.8 1.9 1.9v.6z"
            fill="currentColor"
            class="text-blue-600"
        />
    </svg>
</template>

<script>
export default {
    props: {
        small: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
