import Vue from "vue";
import VueFormulate from "@braid/vue-formulate";
import { de } from "@braid/vue-formulate-i18n";
import MyFile from "./FormulateInputs/MyFile";
Vue.component("MyFile", MyFile);

Vue.use(VueFormulate, {
    plugins: [de],
    locale: "de",
    uploader: async function (file, progress, error, options) {
        try {
            const formData = new FormData();
            formData.append("document", file);
            return await window.axios
                .post(options.uploadUrl, formData, {
                    onUploadProgress: (progressEvent) => {
                        progress(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                    },
                })
                .then((res) => res.data);
        } catch (err) {
            error(
                "Dokument konnte nicht hochgeladen werden. Sie können es später nachreichen."
            );
        }
    },
    library: {
        file: {
            slotComponents: {
                file: "MyFile",
            },
        },
    },
});
