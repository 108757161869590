<template>
    <ApplicationStep :arcanist="arcanist" :errors="errors">
        <template v-slot:above-submit>
            <p
                class="text-sm text-gray-500 -mt-6"
                v-if="arcanist.step.documents && arcanist.step.documents.length"
            >
                Sie können Dokumente bei Bedarf in der Zusammenfassung
                entfernen.
            </p>
        </template>
    </ApplicationStep>
</template>

<script>
export default {
    props: {
        arcanist: {
            type: Object,
        },
        errors: {
            type: Object,
        },
    },
};
</script>
