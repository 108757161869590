<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        stroke-width="2"
    >
        <g stroke-width="2" transform="translate(0, 0)">
            <polygon
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
                stroke-miterlimit="10"
                points="22,12 22,20 12,12 2,20 2,12 12,4 "
                transform="translate(0, 0)"
                stroke-linejoin="miter"
            ></polygon>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
