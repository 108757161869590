<template>
    <div class="text-blue-900">
        <Hero></Hero>

        <slot></slot>

        <Footer :expanded="true"></Footer>
    </div>
</template>

<script>
export default {};
</script>
