<template>
    <div class="relative bg-gray-50 overflow-hidden">
        <div class="max-w-7xl mx-auto">
            <div
                class="
                    relative
                    z-10
                    pb-8
                    bg-gray-50
                    sm:pb-16
                    md:pb-20
                    lg:max-w-2xl lg:w-full lg:pb-28
                    xl:pb-32
                "
            >
                <svg
                    class="
                        hidden
                        lg:block
                        absolute
                        right-0
                        inset-y-0
                        h-full
                        w-48
                        text-gray-50
                        transform
                        translate-x-1/2
                    "
                    fill="currentColor"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                    aria-hidden="true"
                >
                    <polygon points="50,0 100,0 50,100 0,100" />
                </svg>

                <div class="relative pt-8 px-4 sm:px-6 lg:px-8">
                    <nav
                        class="
                            relative
                            flex
                            items-center
                            justify-between
                            sm:h-10
                            lg:h-auto lg:justify-start
                        "
                        aria-label="Global"
                    >
                        <div
                            class="
                                flex
                                items-center
                                flex-grow flex-shrink-0
                                lg:flex-grow-0
                            "
                        >
                            <div
                                class="
                                    flex
                                    items-center
                                    justify-between
                                    w-full
                                    md:w-auto
                                "
                            >
                                <Link href="/">
                                    <span class="sr-only"
                                        >Warnemünder Bau GmbH</span
                                    >
                                    <img
                                        class="h-16 w-auto"
                                        src="/img/logo.svg"
                                        alt="Logo Warnemünder Bau GmbH"
                                    />
                                </Link>
                            </div>
                        </div>
                    </nav>
                </div>

                <main
                    class="
                        mt-10
                        mx-auto
                        max-w-7xl
                        px-4
                        sm:mt-12 sm:px-6
                        md:mt-16
                        lg:mt-20 lg:px-8
                        xl:mt-28
                    "
                >
                    <div class="sm:text-center lg:text-left">
                        <h1
                            class="
                                text-4xl
                                tracking-tight
                                font-extrabold
                                text-blue-900
                                sm:text-5xl
                                md:text-6xl
                            "
                        >
                            <span class="block">Starte bei uns</span>
                            {{ " " }}
                            <span class="block text-blue-600"
                                >deine Karriere</span
                            >
                        </h1>
                        <p
                            class="
                                mt-3
                                text-base text-blue-800
                                sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto
                                md:mt-5 md:text-xl
                                lg:mx-0
                            "
                        >
                            Seit Bestehen der Warnemünder Bau GmbH legt die
                            Geschäftsführung großen Wert auf die Entwicklung des
                            Personals. Im Fokus stehen dabei die Kompetenzen der
                            Mitarbeiter, ihre entsprechenden Qualifikationen und
                            die Anpassungen an den sich permanent verändernden
                            Markt.
                        </p>
                        <div
                            class="
                                mt-5
                                hidden
                                sm:block sm:mt-8 sm:flex sm:justify-center
                                lg:justify-start
                            "
                        >
                            <div class="rounded-md shadow inline-block">
                                <Link
                                    :href="$route('jobs')"
                                    class="btn btn--big"
                                >
                                    Alle Jobs
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>

        <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <!--See app.blade.php for preload statement-->
            <img
                class="
                    w-full
                    object-cover
                    h-[70vw]
                    max-h-[380px]
                    sm:h-[58vw] sm:max-h-[450px]
                    md:object-contain md:h-96
                    lg:object-cover lg:w-full lg:h-full lg:max-h-full
                    max-w-[1240px]
                "
                sizes="(max-width: 1240px) 100vw, 1240px"
                srcset="
                    /img/hero/hero_bjngb7_c_scale_w_320.jpg   320w,
                    /img/hero/hero_bjngb7_c_scale_w_554.jpg   554w,
                    /img/hero/hero_bjngb7_c_scale_w_703.jpg   703w,
                    /img/hero/hero_bjngb7_c_scale_w_914.jpg   914w,
                    /img/hero/hero_bjngb7_c_scale_w_1094.jpg 1094w,
                    /img/hero/hero_bjngb7_c_scale_w_1187.jpg 1187w,
                    /img/hero/hero_bjngb7_c_scale_w_1227.jpg 1227w,
                    /img/hero/hero_bjngb7_c_scale_w_1240.jpg 1240w
                "
                src="/img/hero/hero_bjngb7_c_scale_w_1240.jpg"
                alt="Bild von Baustelle Petriviertel"
            />
        </div>
    </div>
</template>

<script>
export default {};
</script>
