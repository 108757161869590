<template>
    <footer
        class="bg-gray-100 relative z-20"
        :class="expanded && 'lg:bg-crane'"
    >
        <div
            v-if="expanded"
            class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:mt-20 bg"
        >
            <h3
                class="
                    text-3xl
                    font-extrabold
                    tracking-tight
                    sm:text-4xl
                    mb-5
                    lg:mb-10
                "
            >
                Ihre Ansprechpartnerin<br />bei weiteren Fragen
            </h3>
            <div>
                <div class="space-y-5 lg:flex lg:space-x-6">
                    <img
                        src="/img/humans/Richert-F..jpg"
                        alt="Portrait"
                        loading="lazy"
                        class="object-cover shadow-lg rounded-lg max-w-[275px]"
                    />

                    <div class="text-lg leading-6 text-blue-800 space-y-5">
                        <p>
                            <span class="font-semibold"
                                >Frau Franziska Raeuber</span
                            ><br />
                            <span class="text-blue-600"
                                >Prokuristin / Personal</span
                            >
                        </p>

                        <p class="text-lg leading-6 text-blue-800">
                            Tel.: 0381 / 54 82 055<br />
                            E-Mail: raeuber@warbau.de
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="
                max-w-7xl
                mx-auto
                py-8
                px-4
                sm:px-6
                md:flex md:items-center
                lg:px-8
            "
        >
            <div class="lg:flex justify-center space-x-5 md:order-2">
                <p class="text-base text-blue-800">
                    &copy; 2021 | Warnemünder Bau GmbH
                </p>
                <a
                    v-for="item in navigation"
                    :key="item.name"
                    :href="item.href"
                    class="text-blue-600 hover:text-blue-800"
                >
                    {{ item.name }}
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
const navigation = [
    {
        name: "Impressum",
        href: "https://warnemuenderbau.de/impressum/",
    },
    {
        name: "Datenschutz",
        href: "https://warnemuenderbau.de/datenschutz-2/",
    },
];

export default {
    data() {
        return {
            navigation,
        };
    },
    props: {
        expanded: {
            type: Boolean,
        },
    },
};
</script>

<style scoped>
.lg\:bg-crane {
    background-size: auto 90%;
    background-position: 70% 100%;
    background-repeat: no-repeat;
}
</style>
