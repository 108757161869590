<template>
    <Card
        :style="{
            '--transition-timing': `${transition.timing}ms`,
            '--transition-breakpoint': `${transition.breakpoint}px`,
        }"
    >
        <div
            class="
                bg-white
                px-4
                sm:rounded-t-md
                py-5
                border-b border-gray-200
                flex
                items-center
                sm:px-6
            "
        >
            <CategoryDropdown
                @reset="onCategoryReset"
                @change="onCategoryChange"
            />
        </div>

        <div
            v-if="!ads.length"
            class="
                py-16
                bg-white
                lg:bg-transparent lg:py-0 lg:h-[75vh]
                flex flex-col
                items-center
                justify-center
            "
        >
            <p class="mb-4 text-xl text-blue-600">
                Keine offenen Stellenangebote.
            </p>
            <p class="text-base prose text-center mb-4">
                Zurzeit sind keine offenen Stellenangebote vorhanden.<br />Initiativbewerbungen
                sind hingegen immer erwünscht!
            </p>
        </div>

        <div class="lg:flex lg:h-[75vh]" v-if="ads.length">
            <ul
                class="
                    bg-white
                    divide-y divide-gray-200
                    w-full
                    lg:w-2/5
                    h-full
                    border-r border-gray-200
                    overflow-y-auto
                "
                scroll-region
            >
                <li v-for="ad in ads" :key="ad.id">
                    <AdLink
                        :ad="ad"
                        :active-ad-id="activeAd ? activeAd.id : null"
                    />
                </li>
            </ul>

            <div
                key="none-selected"
                class="
                    w-full
                    h-full
                    justify-center
                    items-center
                    hidden
                    lg:flex
                    shadow-2xl
                    lg:shadow-none lg:w-3/5
                    overflow-y-auto
                    h-full
                    absolute
                    right-0
                    z-10
                "
            >
                <p class="text-lg text-gray-500">
                    Wählen Sie ein Stellenangebot aus der Seitenleiste aus.
                </p>
            </div>

            <transition name="slide-fade">
                <Ad
                    v-if="showActiveAd"
                    :ad="activeAd"
                    @close="hideActiveAd"
                ></Ad>
            </transition>
        </div>
    </Card>
</template>

<script>
export default {
    props: {
        ads: {
            type: Array,
        },
        activeAd: {
            type: Object,
            default: null,
        },
    },
    mounted() {
        this.initActiveAdVisibility();
    },
    data() {
        return {
            showActiveAd: false,
            transition: {
                timing: 300,
                breakpoint: 1024,
            },
        };
    },
    methods: {
        onCategoryChange(category) {
            this.$inertia.get(
                route("jobs", {
                    category: category,
                })
            );
        },
        onCategoryReset() {
            this.$inertia.get(route("jobs"));
        },
        initActiveAdVisibility() {
            if (this.activeAd) {
                this.showActiveAd = true;
                return;
            }
            this.showActiveAd = false;
        },
        hideActiveAd() {
            if (this.activeAd === null) {
                return;
            }
            if (
                window.matchMedia(
                    `(max-width: ${this.transition.breakpoint}px)`
                ).matches
            ) {
                this.showActiveAd = false;
                setTimeout(() => {
                    this.navigateToList();
                }, this.transition.timing);
            } else {
                this.navigateToList();
            }
        },
        navigateToList() {
            this.$inertia.get(
                route("jobs", {
                    category: this.$page.props.categories.selected,
                })
            );
        },
    },
};
</script>

<!--suppress CssUnresolvedCustomProperty -->
<style scoped>
@media only screen and (max-width: 1024px) {
    .slide-fade-enter-active,
    .slide-fade-leave-active {
        transition: all var(--transition-timing) cubic-bezier(0.8, 0, 0.4, 1);
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
        transform: translateX(2rem);
        opacity: 0;
    }
}
</style>

<style scoped>
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f6f6f6;
}

::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background: #ddd;
}

::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}
</style>
