<template>
    <ApplicationStep
        :arcanist="arcanist"
        :errors="errors"
        :wide="true"
        submit-label="Bewerbung einreichen"
    >
        <template v-slot:content>
            <p class="mb-8">
                Bevor Sie Ihre Bewerbung abschicken, können Sie Ihre Eingaben
                nochmal überprüfen.
            </p>

            <div class="flex items-center mb-4 pr-2">
                <h2 class="font-bold text-lg text-gray-500">
                    {{ arcanist.wizard.steps[1].title }}
                </h2>
                <div
                    class="border-t mt-0.5 mx-4 border-gray-200 flex-grow"
                ></div>
                <Link
                    class="
                        block
                        text-sm text-blue-600
                        underline
                        hover:text-blue-800
                    "
                    :href="arcanist.wizard.steps[1].url"
                    >Ändern
                </Link>
            </div>

            <dl
                class="
                    grid grid-cols-1
                    gap-x-4 gap-y-4
                    md:gap-y-8
                    sm:grid-cols-2
                    mb-8
                    max-w-3xl
                "
            >
                <div class="sm:col-span-2">
                    <dt class="font-medium text-gray-500">Bewerbung als</dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.ad.title }}
                    </dd>
                </div>
                <div class="sm:col-span-1">
                    <dt class="font-medium text-gray-500">Name</dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.data.name }}
                    </dd>
                </div>
                <div class="sm:col-span-1">
                    <dt class="font-medium text-gray-500">E-Mail</dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.data.email }}
                    </dd>
                </div>
                <div class="sm:col-span-1">
                    <dt class="font-medium text-gray-500">Telefonnummer</dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.data.phone }}
                    </dd>
                </div>
                <div class="sm:col-span-1">
                    <dt class="font-medium text-gray-500">
                        Straße und Hausnummer
                    </dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.data.street_and_number }}
                    </dd>
                </div>
                <div class="sm:col-span-1">
                    <dt class="font-medium text-gray-500">PLZ</dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.data.zip }}
                    </dd>
                </div>
                <div class="sm:col-span-1">
                    <dt class="font-medium text-gray-500">Stadt</dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.data.city }}
                    </dd>
                </div>
            </dl>

            <div class="flex items-center mb-4 pr-2">
                <h2 class="font-bold text-lg text-gray-500">
                    {{ arcanist.wizard.steps[2].title }}
                </h2>
                <div
                    class="border-t mt-0.5 mx-4 border-gray-200 flex-grow"
                ></div>
                <Link
                    class="
                        block
                        text-sm text-blue-600
                        underline
                        hover:text-blue-800
                    "
                    :href="arcanist.wizard.steps[2].url"
                    >Ändern
                </Link>
            </div>

            <dl
                class="
                    grid grid-cols-1
                    gap-x-4 gap-y-4
                    md:gap-y-8
                    sm:grid-cols-2
                    mb-8
                    max-w-3xl
                "
            >
                <div class="sm:col-span-1" v-if="arcanist.step.data.graduation">
                    <dt class="font-medium text-gray-500">
                        Welchen Schulabschluss haben Sie/streben Sie an?
                    </dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.data.graduation }}
                    </dd>
                </div>
                <div
                    class="sm:col-span-1"
                    v-if="arcanist.step.data.start_of_training"
                >
                    <dt class="font-medium text-gray-500">
                        Wann soll Ihre Ausbildung/Praktikum beginnen?
                    </dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.data.start_of_training }}
                    </dd>
                </div>
                <div
                    class="sm:col-span-2"
                    v-if="arcanist.step.data.work_history"
                >
                    <dt class="font-medium text-gray-500">
                        Wo haben Sie die letzten 3 Jahre gearbeitet?
                    </dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.data.work_history }}
                    </dd>
                </div>
                <div class="sm:col-span-1" v-if="arcanist.step.data.unemployed">
                    <dt class="font-medium text-gray-500">
                        Ich war zuletzt erwerbslos.
                    </dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.data.unemployed ? "Ja" : "Nein" }}
                    </dd>
                </div>
                <div
                    class="sm:col-span-1"
                    v-if="arcanist.step.data.start_of_employment"
                >
                    <dt class="font-medium text-gray-500">
                        Wann möchten Sie bei uns anfangen?
                    </dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.data.start_of_employment }}
                    </dd>
                </div>
                <div
                    class="sm:col-span-1"
                    v-if="
                        arcanist.step.data.drivers_license_and_car !== undefined
                    "
                >
                    <dt class="font-medium text-gray-500">
                        Führerschein und Fahrzeug
                    </dt>
                    <dd class="text-gray-900">
                        {{ driversLicenseAndCarLabel }}
                    </dd>
                </div>
                <div
                    class="sm:col-span-2"
                    v-if="arcanist.step.data.additional_info"
                >
                    <dt class="font-medium text-gray-500">
                        Das würde ich gerne noch wissen oder möchte ich
                        mitteilen:
                    </dt>
                    <dd class="text-gray-900">
                        {{ arcanist.step.data.additional_info }}
                    </dd>
                </div>
            </dl>

            <div class="flex items-center mb-4 pr-2">
                <h2 class="font-bold text-lg text-gray-500">
                    {{ arcanist.wizard.steps[3].title }}
                </h2>
                <div
                    class="border-t mt-0.5 mx-4 border-gray-200 flex-grow"
                ></div>
                <Link
                    class="
                        block
                        text-sm text-blue-600
                        underline
                        hover:text-blue-800
                    "
                    :href="arcanist.wizard.steps[3].url"
                    >Ändern
                </Link>
            </div>

            <p
                v-if="
                    !arcanist.step.data.documents ||
                    !arcanist.step.data.documents.length
                "
            >
                Sie haben keine Dokumente hochgeladen.
            </p>

            <ul
                role="list"
                class="
                    border border-gray-200
                    rounded-md
                    divide-y divide-gray-200
                "
                v-if="
                    arcanist.step.data.documents &&
                    arcanist.step.data.documents.length
                "
            >
                <li
                    class="
                        pl-3
                        pr-4
                        py-3
                        flex
                        items-center
                        justify-between
                        text-sm
                        bg-white bg-opacity-50
                        hover:bg-opacity-75
                    "
                    v-for="document in arcanist.step.data.documents"
                >
                    <div class="w-0 flex-1 flex items-center">
                        <!-- Heroicon name: solid/paper-clip -->
                        <svg
                            class="flex-shrink-0 h-5 w-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                clip-rule="evenodd"
                            />
                        </svg>
                        <span class="ml-2 flex-1 w-0 truncate">{{
                            document.name
                        }}</span>
                    </div>
                </li>
            </ul>
        </template>
    </ApplicationStep>
</template>

<script>
export default {
    props: {
        arcanist: {
            type: Object,
        },
        errors: {
            type: Object,
        },
    },
    computed: {
        /**
         * @returns {string}
         */
        driversLicenseAndCarLabel() {
            const values = this.arcanist.step.data.drivers_license_and_car;

            if (values === null) {
                return [
                    "kein eigenes Fahrzeug",
                    "kein Führerschein der Klasse B",
                ].join(", ");
            }
            if (Array.isArray(values) && !values.length) {
                return [
                    "kein eigenes Fahrzeug",
                    "kein Führerschein der Klasse B",
                ].join(", ");
            }
            if (Array.isArray(values)) {
                let labels = [];
                labels.push(
                    values.includes("car")
                        ? "eigenes Fahrzeug"
                        : "kein eigenes Fahrzeug"
                );
                labels.push(
                    values.includes("license")
                        ? "Führerschein der Klasse B"
                        : "kein Führerschein der Klasse B"
                );
                return labels.join(", ");
            }

            return "–";
        },
    },
};
</script>
