<template>
    <div class="bg-gray-50">
        <div class="max-w-7xl mx-auto">
            <div class="pt-8 pb-10 px-4 sm:px-6 lg:px-8 flex items-center">
                <Link href="/">
                    <span class="sr-only">Warnemünder Bau GmbH</span>
                    <img
                        class="h-16 w-auto"
                        src="/img/logo.svg"
                        alt="Logo Warnemünder Bau GmbH"
                    />
                </Link>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>
