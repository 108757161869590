<template>
    <svg
        viewBox="0 0 20 20"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        v-if="small"
    >
        <line
            fill="none"
            stroke="currentColor"
            stroke-miterlimit="10"
            x1="13.2"
            y1="14"
            x2="9.6"
            y2="14"
        />
        <path
            fill="currentColor"
            d="M11.8,2.3c0-0.2-0.2-0.4-0.4-0.4S11,2,11,2.3H11.8z"
        />
        <circle
            fill="none"
            stroke="currentColor"
            stroke-miterlimit="10"
            cx="3.8"
            cy="3.3"
            r="1.9"
        />
        <path
            fill="none"
            stroke="currentColor"
            stroke-miterlimit="10"
            d="M10.5,6.8l-7.1,0c-0.9,0-2.5,0.7-2.5,2.3l0,3l1.4,1.3l0,5.2H5l0.9-9 h4.6c0.5,0,0.9-0.4,0.9-0.9V7.7C11.5,7.2,11.1,6.8,10.5,6.8z"
        />
        <path
            fill="currentColor"
            d="M7.8 16.6C7.4 16.6 7 17 7 17.4c0 .4.4.8.8.8s.8-.4.8-.8C8.6 17 8.2 16.6 7.8 16.6zM15 16.6c-.4 0-.8.4-.8.8 0 .4.4.8.8.8.4 0 .8-.4.8-.8C15.8 17 15.4 16.6 15 16.6z"
        />
        <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="square"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="M5.5,14 c1.4,0,4.1,0,4.1,0l-1.8,3.4"
        />
        <polyline
            fill="none"
            stroke="currentColor"
            stroke-linecap="square"
            stroke-miterlimit="10"
            points="15,17.4 13.2,14 17.9,14 17.9,4.6"
        />
        <polygon
            fill="currentColor"
            stroke="currentColor"
            stroke-miterlimit="10"
            points="6,2.7 18.9,2.7 18.9,4.1 5.6,4.1 6,3.4"
        />
    </svg>
    <svg
        viewBox="0 0 48 48"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        v-else
    >
        <path
            class="text-blue-50"
            d="M10.9 9.7h34.4v23.5H10.9z"
            fill="currentColor"
        />
        <path
            class="text-blue-600"
            d="M14.5 9.2c-.2.4-.4.7-.7 1h31v22.5H14.7l-.1 1h31.2V9.2H14.5z"
            fill="currentColor"
        />
        <path
            class="text-blue-100"
            d="M10.9 6h35.6v3.7H10.9z"
            fill="currentColor"
        />
        <path
            class="text-blue"
            d="M15.2 5.5v1H46v2.7H14.5c-.2.4-.4.7-.7 1H47V5.5H15.2zM29.2 5.5c0-.6-.5-1.1-1.1-1.1-.6 0-1.1.5-1.1 1.1h2.2z"
            fill="currentColor"
        />
        <path
            class="text-blue-100"
            d="M8.6 10.9c-2.7 0-4.8-2.2-4.8-4.8S6 1.3 8.6 1.3s4.8 2.2 4.8 4.8-2.1 4.8-4.8 4.8z"
            fill="currentColor"
        />
        <path
            class="text-blue-600"
            d="M8.6 1.7c2.4 0 4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3S4.3 8.4 4.3 6s1.9-4.3 4.3-4.3m0-1C5.7.7 3.3 3.1 3.3 6s2.4 5.3 5.3 5.3S13.9 9 13.9 6 11.5.7 8.6.7z"
            fill="currentColor"
        />
        <path
            class="text-blue-100"
            d="M5.1 45 5 32.1l-3.5-3.3.1-10.4c0-3.8 3.9-5.4 6-5.4h18.1c1 0 1.9.8 1.9 1.9s-.8 1.9-1.9 1.9H13.5L11.2 45H5.1z"
            fill="currentColor"
        />
        <path
            class="text-blue-600"
            d="M25.8 13.5c.8 0 1.4.6 1.4 1.4 0 .8-.6 1.4-1.4 1.4H13l-.1.9-2.2 27.2H5.6l-.1-12.1v-.4l-.3-.3-3.2-3 .1-10.2c0-3.4 3.6-4.9 5.5-4.9h18.2m0-1H7.7c-2.4 0-6.5 1.8-6.5 5.9L1 29l3.5 3.3.1 13.1h7l2.3-28.2h11.8c1.3 0 2.4-1.1 2.4-2.4 0-1.2-1-2.3-2.3-2.3zM18.8 40.8c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.8-1.8-1.8-1.8zM37.1 40.8c-1 0-1.8.8-1.8 1.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8-.8-1.8-1.8-1.8z"
            fill="currentColor"
        />
        <g class="text-blue-600" fill="currentColor">
            <path
                d="m18.8 42.6 4.5-8.9M18.124 42.743l4.974-9.812.892.452-4.974 9.812z"
            />
        </g>
        <g class="text-blue-600" fill="currentColor">
            <path
                d="m37.1 42.6-4.5-8.9M31.955 33.4l.892-.453 4.972 9.812-.892.452z"
            />
        </g>
        <path
            class="text-blue-600"
            d="M27.1 33.7v3.1c0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1v-3.1h-2.2z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
export default {
    props: {
        small: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
