<template>
    <div class="flex space-x-2 items-center min-w-[200px]">
        <label
            for="category"
            class="text-lg leading-6 font-medium text-gray-900"
        >
            Kategorie
        </label>
        <select
            class="
                mt-1
                block
                w-full
                pl-3
                pr-10
                py-2
                text-base
                border-gray-300
                focus:outline-none focus:ring-indigo-500 focus:border-indigo-500
                sm:text-sm
                rounded-md
            "
            id="category"
            name="category"
            v-model="selectedCategory"
        >
            <option value="all">Alle</option>
            <template v-for="category in categories">
                <option :value="category.slug" class="font-semibold">
                    {{ category.name }}
                </option>
                <template v-for="subCategory in category.sub_categories">
                    <option :value="subCategory.slug" class="ml-2">
                        &nbsp;&nbsp;&nbsp;{{ subCategory.name }}
                    </option>
                </template>
            </template>
        </select>
    </div>
</template>

<script>
export default {
    computed: {
        categories() {
            return this.$page.props.categories.all;
        },
    },
    data() {
        return {
            selectedCategory: this.$page.props.categories.selected || "all",
        };
    },
    watch: {
        selectedCategory: function () {
            this.selectedCategory !== "all"
                ? this.$emit("change", this.selectedCategory)
                : this.$emit("reset");
        },
    },
};
</script>
