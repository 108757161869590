<template>
    <div>
        <Head>
            <title>{{ title }} | Warnemünder Bau GmbH</title>
        </Head>

        <Layout>
            <AdList :ads="ads" :active-ad="activeAd"></AdList>
        </Layout>
    </div>
</template>

<script>
export default {
    props: {
        ads: {
            type: Array,
        },
        activeAdId: {
            type: Number,
            default: null,
        },
    },
    computed: {
        title() {
            return !this.activeAdId ? "Alle Jobs" : this.activeAd.title;
        },
        activeAd() {
            return this.ads.find((ad) => {
                return parseInt(ad.id) === this.activeAdId;
            });
        },
    },
};
</script>
