<template>
    <nav aria-label="Progress">
        <ol role="list" class="space-y-4 md:flex md:space-y-0 md:space-x-8">
            <li class="md:flex-1" v-for="(step, index) in visibleSteps">
                <!-- Completed Step -->
                <div
                    class="
                        pl-4
                        py-2
                        flex flex-col
                        border-l-4 border-blue-500
                        md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4
                    "
                    v-if="step.isComplete && !step.active"
                    data-completed
                >
                    <span
                        class="
                            text-xs text-gray-600
                            font-bold
                            tracking-wide
                            uppercase
                        "
                        >Schritt {{ index + 1 }}</span
                    >
                    <span class="text-sm font-semibold">{{ step.title }}</span>
                </div>

                <!-- Active Step -->
                <div
                    class="
                        pl-4
                        py-2
                        flex flex-col
                        border-l-4 border-blue-700
                        md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4
                    "
                    aria-current="step"
                    v-if="step.active"
                    data-active
                >
                    <span
                        class="
                            text-xs text-blue-700
                            font-bold
                            tracking-wide
                            uppercase
                        "
                        >Schritt {{ index + 1 }}</span
                    >
                    <span class="text-sm font-semibold">{{ step.title }}</span>
                </div>

                <!-- Upcoming Step -->
                <div
                    class="
                        pl-4
                        py-2
                        flex flex-col
                        border-l-4 border-gray-200
                        md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4
                    "
                    v-if="!step.isComplete && !step.active"
                    data-upcoming
                >
                    <span
                        class="
                            text-xs text-gray-500
                            font-bold
                            tracking-wide
                            uppercase
                        "
                        >Schritt {{ index + 1 }}</span
                    >
                    <span class="text-sm text-gray-600 font-semibold">{{
                        step.title
                    }}</span>
                </div>
            </li>
        </ol>
    </nav>
</template>

<script>
export default {
    name: "FormProgress",
    props: {
        steps: {
            type: Array,
            default: [],
        },
    },
    computed: {
        visibleSteps: function () {
            return this.steps.slice(1);
        },
    },
};
</script>
