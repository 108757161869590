<template>
    <Layout>
        <Head>
            <title>{{ title }} | Warnemünder Bau GmbH</title>
        </Head>

        <Card class="min-h-[60vh]">
            <div class="px-4 md:px-8 md:py-16 max-w-5xl mx-auto">
                <h1 class="text-2xl font-medium text-blue-600 mb-4">
                    {{ arcanist.step.ad.title }}
                </h1>

                <div
                    class="
                        flex
                        items-center
                        text-sm text-gray-500
                        mb-6
                        md:mb-12
                    "
                >
                    <component
                        :is="arcanist.step.ad.category.icon"
                        class="h-5 w-5 text-gray-400 mr-2"
                        :small="true"
                        aria-hidden="true"
                    />
                    <span class="mr-4">{{
                        arcanist.step.ad.category.name
                    }}</span>

                    <svg
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        ></path>
                    </svg>

                    <span class="mr-4"
                        >Ab&nbsp;<DateTime
                            :iso-datetime="arcanist.step.ad.startingAt"
                            format="DD.MM.YY"
                    /></span>

                    <svg
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                    </svg>
                    <span v-if="arcanist.step.ad.duration">{{
                        arcanist.step.ad.readableDuration
                    }}</span>
                    <span v-if="arcanist.step.ad.isPermanent">Unbefristet</span>
                </div>

                <FormProgress
                    class="mb-6 md:mb-12"
                    :steps="arcanist.wizard.steps"
                ></FormProgress>

                <slot name="content"></slot>

                <FormulateForm
                    @submit="onSubmit"
                    :values="values"
                    :schema="schema"
                    :errors="errors"
                    :class="{ 'max-w-2xl': !wide }"
                    #default="{ isLoading }"
                >
                    <slot name="above-submit"></slot>

                    <div
                        class="
                            flex flex-row-reverse
                            items-start
                            justify-between
                            mt-12
                        "
                    >
                        <FormulateInput
                            type="submit"
                            :disabled="isLoading"
                            :label="isLoading ? 'Sende...' : submitLabel"
                        />

                        <Link
                            class="btn btn--secondary"
                            :href="previousStepUrl"
                            v-if="currentStepIndex !== 1"
                            >Zurück</Link
                        >
                    </div>
                </FormulateForm>
            </div>
        </Card>
    </Layout>
</template>

<script>
import ApplicationStep from "../../../Mixins/ApplicationStep";

export default {
    mixins: [ApplicationStep],
    computed: {
        title() {
            return `Bewerben als ${this.arcanist.step.ad.title}`;
        },
    },
};
</script>
