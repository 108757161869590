<template>
    <div
        class="
            p-8
            md:p-[5vw]
            lg:p-8
            bg-white
            fixed
            inset-y-0
            left-[5vw]
            md:left-[12vw]
            right-0
            w-[95vw]
            shadow-2xl
            lg:static lg:shadow-none lg:w-3/5
            overflow-y-auto
            h-full
            z-20
        "
    >
        <button
            @click.prevent="onClose"
            class="inline-block lg:hidden btn btn--link mb-2"
        >
            &lsaquo; Zur Liste
        </button>

        <h2 class="text-2xl font-medium text-blue-600 mb-4">{{ ad.title }}</h2>

        <div class="flex items-center text-sm text-gray-500 mb-8">
            <component
                :is="ad.category.icon"
                class="h-5 w-5 text-gray-400 mr-2"
                aria-hidden="true"
                :small="true"
            />
            <span class="mr-4">{{ ad.category.name }}</span>

            <svg
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                xml:space="preserve"
            >
                <g fill="none" stroke="currentColor" stroke-miterlimit="10">
                    <path
                        d="M15.7,3.9H4.3c-0.9,0-1.7,0.7-1.7,1.7v10.1c0,0.9,0.7,1.7,1.7,1.7 h11.4c0.9,0,1.7-0.7,1.7-1.7V5.6C17.3,4.7,16.6,3.9,15.7,3.9z"
                    />
                    <line
                        stroke-linecap="round"
                        x1="5"
                        y1="9.4"
                        x2="15"
                        y2="9.4"
                    />
                    <line
                        stroke-linecap="round"
                        x1="5.6"
                        y1="5.7"
                        x2="5.6"
                        y2="2.2"
                    />
                    <line
                        stroke-linecap="round"
                        x1="14.5"
                        y1="5.7"
                        x2="14.5"
                        y2="2.2"
                    />
                </g>
            </svg>

            <span class="mr-4"
                >Ab&nbsp;<DateTime
                    :iso-datetime="ad.startingAt"
                    format="DD.MM.YY"
            /></span>

            <svg
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
            </svg>
            <span v-if="ad.duration">{{ ad.readableDuration }}</span>
            <span v-if="ad.isPermanent">Unbefristet</span>
        </div>

        <Link
            :href="ad.applicationRoute"
            method="POST"
            as="button"
            class="btn btn--big mb-8"
        >
            Jetzt bewerben
        </Link>
        <div class="prose" v-html="ad.description"></div>
    </div>
</template>

<script>
export default {
    props: {
        ad: {
            type: Object,
            required: true,
        },
    },
    methods: {
        onClose() {
            this.$emit("close");
        },
    },
};
</script>
