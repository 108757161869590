<template>
    <ApplicationStep :arcanist="arcanist" :errors="errors">
        <template v-slot:above-submit>
            <a
                class="
                    inline-block
                    -mt-4
                    mb-6
                    text-blue
                    no-underline
                    hover:underline
                "
                href="https://warnemuenderbau.de/datenschutz-2/"
                target="_blank"
                title="Datenschutzhinweis in neuem Tab öffnen"
                >Zum Datenschutzhinweis</a
            >
        </template>
    </ApplicationStep>
</template>

<script>
export default {
    props: {
        arcanist: {
            type: Object,
        },
        errors: {
            type: Object,
        },
    },
};
</script>
