<template>
    <ApplicationStep :arcanist="arcanist" :errors="errors"> </ApplicationStep>
</template>

<script>
export default {
    props: {
        arcanist: {
            type: Object,
        },
        errors: {
            type: Object,
        },
    },
};
</script>
