<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="relative py-16 sm:py-18 lg:py-20">
        <div
            class="
                mx-auto
                max-w-md
                px-4
                text-center
                sm:max-w-3xl sm:px-6
                lg:px-8 lg:max-w-7xl
            "
        >
            <p class="my-5 max-w-prose mx-auto text-xl text-blue-800">
                Wir beschäftigen rund 65 Mitarbeiter und Mitarbeiterinnen. Die
                Teams setzen sich für jedes Projekt individuell und je nach
                Bedarf aus Maurern, Zimmerern, Betonbauern, Elektrikern,
                Kaufleuten, Bauleitern und Kalkulatoren zusammen. Finden Sie das
                passende Stellenangebote und werden Sie Teil der Warnemünder Bau
                GmbH.
            </p>
            <a
                :href="$route('jobs')"
                class="
                    rounded
                    shadow
                    mx-auto
                    inline-block
                    px-8
                    py-3
                    border border-transparent
                    text-base
                    font-medium
                    rounded-md
                    text-white
                    bg-blue-600
                    hover:bg-blue-700
                    md:py-4 md:text-lg md:px-10
                "
            >
                Jetzt bewerben
            </a>
        </div>
    </div>
</template>

<script>
export default {};
</script>
