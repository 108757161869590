<template>
    <div class="text-blue-900">
        <PageTop></PageTop>

        <div class="bg-gray-50 pb-16 min-h-[85vh]">
            <div class="max-w-7xl mx-auto z20 relative">
                <slot></slot>
            </div>
        </div>

        <Footer />

        <img
            src="/img/crane.svg"
            alt="Kran"
            class="hidden lg:block fixed right-0 bottom-0 z-10 h-[60vh] w-auto"
        />
    </div>
</template>

<script>
export default {};
</script>
