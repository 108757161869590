<template>
    <svg
        ref="icon"
        viewBox="0 0 48 48"
        x="0px"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        y="0px"
    >
        <path
            class="text-blue-100"
            d="M39.6,33.4h7.7V16c0-4.4-3.5-7.9-7.9-7.9H15.5C10.8,8.2,7,12,7,16.7v16.7h8.2H39.6z"
            fill="currentColor"
        />
        <path
            class="text-blue-600"
            d="M47.8,33.9H6.5V16.7c0-5,4.1-9,9-9h23.9c4.6,0,8.4,3.8,8.4,8.4V33.9z M7.5,32.9h39.3V16 c0-4.1-3.3-7.4-7.4-7.4H15.5c-4.4,0-8,3.6-8,8V32.9z"
            fill="currentColor"
        />
        <rect
            class="text-blue-100"
            fill="currentColor"
            height="14"
            width="4.2"
            x="27.5"
            y="33.5"
        />
        <path
            class="text-blue-600"
            d="M31.3,34v13H28V34H31.3 M32.3,33H27v15h5.2V33L32.3,33z"
            fill="currentColor"
        />
        <g class="letter">
            <rect fill="#FFFFFF" height="15.2" width="23.5" x="2.3" y="18.1" />
            <path
                class="text-blue-100"
                d="M1.8,17.6v16.2h24.5V17.6H1.8z M23.8,18.6L14,24l-9.8-5.4H23.8z M2.8,32.9V19L14,25.2L25.3,19v13.9H2.8z"
                fill="currentColor"
            />
        </g>
        <g>
            <rect
                class="text-blue-600"
                fill="currentColor"
                height="1"
                width="46.9"
                x="0.4"
                y="32.9"
            />
        </g>
        <g>
            <path
                class="text-blue-50"
                d="M39.4,8.2c4.4,0,7.9,3.5,7.9,7.9v17.3h-7.7H23.4V16c0-4.4-3.5-7.9-7.9-7.9h0H39.4z"
                fill="currentColor"
            />
            <path
                class="text-blue-600"
                d="M47.8,33.9H22.9V16c0-4.1-3.3-7.4-7.4-7.4v-1h23.9c4.6,0,8.4,3.8,8.4,8.4V33.9z M23.9,32.9h22.9V16 c0-4.1-3.3-7.4-7.4-7.4H19.5c2.6,1.4,4.4,4.2,4.4,7.4V32.9z"
                fill="currentColor"
            />
        </g>
        <g class="flag">
            <rect
                class="text-blue-100"
                fill="currentColor"
                height="5.4"
                width="2.9"
                x="40.3"
                y="14.6"
            />
            <path
                class="text-blue-600"
                d="M39.8,20.5l3.9,0v-5.4v-1l-3.9,0l-8.4,0c-0.2-0.8-0.9-1.4-1.8-1.4c-1,0-1.9,0.8-1.9,1.9 c0,1,0.8,1.9,1.9,1.9c0.9,0,1.6-0.6,1.8-1.4l8.4,0V20.5z M42.7,15.1v4.4h-1.9v-4.4L42.7,15.1z"
                fill="currentColor"
            />
        </g>
    </svg>
</template>

<script>
export default {
    mounted() {
        setTimeout(() => {
            this.$refs.icon.classList.add("animate");
        }, 500);
    },
};
</script>

<style>
.flag {
    transform: rotate(0);
    transition: transform ease-out 0.3s 0.3s;
    transform-origin: 61.77% 30.52%;
}

.letter {
    transition: transform ease-in-out 0.6s;
}

.animate .flag {
    transform: rotate(-90deg);
}

.animate .letter {
    transform: translateX(20%);
}
</style>
