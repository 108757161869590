<template>
    <Link
        preserve-scroll
        :href="ad.route"
        :only="['activeAdId']"
        class="block hover:bg-gray-50"
        :class="{ 'bg-blue-50 hover:bg-blue-50': activeAdId === ad.id }"
    >
        <div class="px-4 py-6 sm:px-6">
            <div class="flex items-center justify-between mb-8">
                <p class="text-lg font-medium text-blue-600">
                    {{ ad.title }}
                </p>
            </div>

            <div class="mt-2 sm:flex sm:justify-between">
                <div class="sm:flex w-full">
                    <p class="flex items-center text-sm text-gray-500">
                        <component
                            :is="ad.category.icon"
                            class="h-5 w-5 text-gray-400 mr-2"
                            aria-hidden="true"
                            :small="true"
                        />
                        {{ ad.category.name }}
                    </p>
                    <p
                        class="
                            mt-2
                            flex
                            items-center
                            text-sm text-gray-500
                            sm:mt-0 sm:ml-6
                        "
                    >
                        <svg
                            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            xml:space="preserve"
                        >
                            <g
                                fill="none"
                                stroke="currentColor"
                                stroke-miterlimit="10"
                            >
                                <path
                                    d="M15.7,3.9H4.3c-0.9,0-1.7,0.7-1.7,1.7v10.1c0,0.9,0.7,1.7,1.7,1.7 h11.4c0.9,0,1.7-0.7,1.7-1.7V5.6C17.3,4.7,16.6,3.9,15.7,3.9z"
                                />
                                <line
                                    stroke-linecap="round"
                                    x1="5"
                                    y1="9.4"
                                    x2="15"
                                    y2="9.4"
                                />
                                <line
                                    stroke-linecap="round"
                                    x1="5.6"
                                    y1="5.7"
                                    x2="5.6"
                                    y2="2.2"
                                />
                                <line
                                    stroke-linecap="round"
                                    x1="14.5"
                                    y1="5.7"
                                    x2="14.5"
                                    y2="2.2"
                                />
                            </g>
                        </svg>

                        Ab&nbsp;<span v-if="ad.asOfNow">sofort</span>
                        <DateTime
                            v-else
                            :iso-datetime="ad.startingAt"
                            format="DD.MM.YY"
                        />
                    </p>

                    <p
                        class="
                            mt-2
                            flex
                            items-center
                            text-sm text-gray-500
                            sm:mt-0 sm:ml-6
                        "
                    >
                        <svg
                            class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                        </svg>
                        <span v-if="ad.duration">{{
                            ad.readableDuration
                        }}</span>
                        <span v-if="ad.isPermanent">Unbefristet</span>
                    </p>
                </div>
            </div>
        </div>
    </Link>
</template>

<script>
export default {
    props: {
        ad: {
            type: Object,
            required: true,
        },
        activeAdId: {
            type: Number,
            default: null,
        },
    },
};
</script>
