<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        stroke-width="2"
    >
        <g stroke-width="2" transform="translate(0, 0)">
            <line
                data-color="color-2"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
                stroke-miterlimit="10"
                x1="1"
                y1="12"
                x2="3"
                y2="12"
                stroke-linejoin="miter"
            ></line>
            <line
                data-color="color-2"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
                stroke-miterlimit="10"
                x1="4.222"
                y1="4.222"
                x2="5.636"
                y2="5.636"
                stroke-linejoin="miter"
            ></line>
            <line
                data-color="color-2"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
                stroke-miterlimit="10"
                x1="12"
                y1="1"
                x2="12"
                y2="3"
                stroke-linejoin="miter"
            ></line>
            <line
                data-color="color-2"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
                stroke-miterlimit="10"
                x1="19.778"
                y1="4.222"
                x2="18.364"
                y2="5.636"
                stroke-linejoin="miter"
            ></line>
            <line
                data-color="color-2"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
                stroke-miterlimit="10"
                x1="23"
                y1="12"
                x2="21"
                y2="12"
                stroke-linejoin="miter"
            ></line>
            <path
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
                stroke-miterlimit="10"
                d="M18,12c0-3.314-2.686-6-6-6 s-6,2.686-6,6c0,2.611,1.671,4.827,4,5.651V22h4v-4.349C16.329,16.827,18,14.611,18,12z"
                stroke-linejoin="miter"
            ></path>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
