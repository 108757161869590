<template>
    <span v-if="!forHumans">{{ absolute }}</span>
    <span v-else :title="absolute">{{ relative }}</span>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import de from "dayjs/locale/de";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(utc);

const locales = { de };

export default {
    /**
     * Datetime MUST be in ISO-8601 time, e.g.
     * 2018-04-04T16:00:00.000Z
     * This format incorporates the timezone and
     * thus, does not need to be parsed as UTC.
     * @see https://en.wikipedia.org/wiki/ISO_8601
     */
    props: {
        isoDatetime: {
            type: String,
            default: "",
        },
        utcDatetime: {
            type: String,
            default: "",
        },
        now: {
            type: Boolean,
            default: false,
        },
        parseFormat: {
            type: String,
            default: "",
        },
        format: {
            type: String,
            default: "DD.MM.YYYY HH:mm:ss",
        },
        forHumans: {
            type: Boolean,
            default: false,
        },
        disableRelativeSuffix: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            relative: "",
            locale: window.locale,
        };
    },

    created() {
        if (!!this.isoDatetime && !!this.utcDatetime) {
            console.warn(
                "Do not set isoDatetime and utcDatetime together. Taking isoDatetime now."
            );
        }
        if (!!this.utcDatetime && !this.parseFormat) {
            console.warn(
                "If you set utcDatetime you must provide parseFormat."
            );
        }

        dayjs.locale(this.locale);

        if (this.forHumans) {
            this.relative = this.calcRelative();
        }
    },

    mounted() {
        if (this.forHumans) {
            setInterval(() => {
                this.relative = this.calcRelative();
            }, 60 * 1000);
        }
    },

    computed: {
        date: function () {
            if (this.now) {
                return dayjs();
            }
            if (!!this.isoDatetime && !!this.utcDatetime) {
                return dayjs(this.isoDatetime);
            }
            if (!!this.isoDatetime && !this.utcDatetime) {
                return dayjs(this.isoDatetime);
            }
            if (!this.isoDatetime && !!this.utcDatetime) {
                return dayjs.utc(this.utcDatetime, this.parseFormat).local();
            }
            return dayjs();
        },
        absolute: function () {
            return this.date.format(this.format);
        },
    },

    methods: {
        calcRelative: function () {
            return this.date.fromNow(this.disableRelativeSuffix);
        },
    },
};
</script>
