<template>
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div class="space-y-24">
            <div
                class="
                    space-y-5
                    sm:space-y-4
                    md:max-w-xl
                    lg:max-w-3xl
                    xl:max-w-none
                    text-center
                    mx-auto
                "
            >
                <p
                    class="
                        text-base
                        font-semibold
                        tracking-wider
                        text-blue-600
                        uppercase
                    "
                >
                    Ihr Kontakt zu uns
                </p>
                <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">
                    Ansprechpartner in Sachen Karriere
                </h2>
                <p class="text-xl text-blue-800 mx-auto max-w-prose">
                    Sie haben Fragen zum Bewerbungsprozess, zu offenen Stellen
                    oder unserem Unternehmen? Bei allen Belangen rund um das
                    Thema Karriere bei der Warnemünder Bau GmbH helfen wir Ihnen
                    gerne weiter.
                </p>
            </div>
            <ul
                class="
                    space-y-12
                    sm:flex
                    sm:justify-center
                    sm:gap-x-6
                    sm:gap-y-12
                    sm:space-y-0
                    lg:gap-x-8
                "
                v-for="row in people"
            >
                <li
                    v-for="person in row"
                    :key="person.name"
                    class="w-full lg:w-1/3"
                >
                    <div class="space-y-4">
                        <div class="aspect-w-3 aspect-h-2">
                            <img
                                class="object-cover shadow-lg rounded-lg"
                                :src="person.imageUrl"
                                :alt="`Portrait ${person.name}`"
                                loading="lazy"
                            />
                        </div>

                        <div class="space-y-2">
                            <div
                                class="
                                    text-lg
                                    leading-6
                                    font-semibold
                                    space-y-1
                                "
                            >
                                <h3>{{ person.name }}</h3>
                                <p class="text-blue-600 font-normal">
                                    {{ person.role }}
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
const people = [
    [
        // {
        //     name: "Herr Th. Richert",
        //     role: "Gesellschafter und Geschäftsführer",
        //     imageUrl: "/img/humans/Richert-Th..jpg",
        // },
        {
            name: "Herr B. Liefke",
            role: "Geschäftsführer",
            imageUrl: "/img/humans/Liefke-2.jpg",
        },
        {
            name: "Frau F. Raeuber",
            role: "Prokuristin / Personal",
            imageUrl: "/img/humans/Richert-F..jpg",
        },
    ],
];

export default {
    data() {
        return {
            people,
        };
    },
};
</script>
