<template>
    <div :class="context.classes.file">
        <svg
            class="flex-shrink-0 h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
        >
            <path
                fill-rule="evenodd"
                d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                clip-rule="evenodd"
            />
        </svg>
        <div
            :class="context.classes.fileName"
            :title="file.name"
            v-text="file.name"
        />
        <div
            v-if="file.progress !== false"
            :data-just-finished="file.justFinished"
            :data-is-finished="!file.justFinished && file.complete"
            :class="context.classes.fileProgress"
        >
            <div
                :class="context.classes.fileProgressInner"
                :style="{ width: file.progress + '%' }"
            />
        </div>
        <div
            v-if="
                (file.complete && !file.justFinished) || file.progress === false
            "
            :class="context.classes.fileRemove"
            @click="onRemoveFile"
            :title="
                isUploaded
                    ? 'Bereits hochgeladenes Dokument löschen'
                    : 'Dokument aus Liste entfernen'
            "
        >
            <svg
                class="h-5 w-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                ></path>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        file: {
            type: Object,
            required: true,
        },
        imagePreview: {
            type: Boolean,
            default: false,
        },
        context: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isUploaded() {
            return this.file.path !== false;
        },
    },
    methods: {
        onRemoveFile(payload) {
            if (!this.isUploaded) {
                this.file.removeFile(payload);
                return;
            }
            this.$inertia.delete(
                route("documents.delete", this.file.path.uuid),
                {
                    onSuccess: () => {
                        this.file.removeFile(payload);
                    },
                }
            );
        },
    },
};
</script>
