<template>
    <svg
        viewBox="0 0 20 20"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        v-if="small"
    >
        <g fill="none" stroke="currentColor" stroke-miterlimit="10">
            <path
                d="m12.1 10.7 1.3-1.3c1.5.6 3.2.4 4.3-.6 1.1-1.1 1.5-3.2.9-4.9l-2.9 2.9-2.4-2.4 2.9-2.9c-1.7-.6-3.8-.2-4.9.9C10.2 3.4 10 5 10.6 6.6L9.3 7.9M3.1 14.1l-1.4 1.4c-.8.8-.8 2 0 2.8.8.8 2 .8 2.8 0l1.4-1.4"
            />
            <path
                d="M13.2,11.7L9.3,7.9L10,7.2L9.5,6.7c-1.3-1.3-3.3-1.3-4.6,0L2.6,9c-1.3,1.3-1.3,3.3,0,4.6c0,0,3.1,3.1,3.3,3.3c0.2,0.2,0.8,0.6,1.5-0.1c0.8-0.8,0.1-1.1,0.1-1.1c0.1-0.1,0.7,1.4,1.6,0.4c1.1-1,0.1-1.6,0.1-1.6c0.1-0.1,0.8,1.3,1.9,0.3c1-1,0-1.6,0.1-1.6c0.1-0.1,0.9,1.3,1.8,0.4C13.8,12.8,13.7,12.2,13.2,11.7z"
            />
        </g>
    </svg>
    <svg
        viewBox="0 0 48 48"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        v-else
    >
        <path
            d="M6.5 46.3c-1.2 0-2.4-.5-3.3-1.4-1.8-1.8-1.8-4.7 0-6.6l22.7-22.7-.2-.4c-1.6-3.9-1.1-8 1.5-10.6 1.9-1.9 4.9-3 8.1-3 1.2 0 2.3.1 3.3.4l-7.5 7.5 7.3 7.3 7.5-7.5c1.1 4.1.1 8.7-2.6 11.4-1.6 1.6-3.8 2.4-6.2 2.4-1.4 0-3-.3-4.4-.9l-.4-.2L9.8 45c-.9.8-2 1.3-3.3 1.3z"
            fill="#CEF3FF"
        />
        <path
            d="M35.4 2.3c.7 0 1.4.1 2.1.2l-6.2 6.2-.9.9.9.9 6.5 6.5.9.9.9-.9 6.2-6.2c.6 3.5-.4 7.5-2.7 9.7-1.5 1.5-3.5 2.3-5.8 2.3-1.4 0-2.8-.3-4.2-.9l-.8-.3-.6.6L9.4 44.5c-.8.8-1.8 1.2-2.8 1.2-1.1 0-2.1-.4-2.8-1.2-1.6-1.6-1.6-4.1 0-5.7l22.4-22.4.6-.6-.3-.8c-1.5-3.7-1-7.5 1.4-9.9 1.6-1.7 4.5-2.8 7.5-2.8m0-1.2c-3.3 0-6.4 1.2-8.5 3.2-2.9 2.9-3.3 7.3-1.7 11.3L2.8 38c-2.1 2.1-2.1 5.4 0 7.4 1 1 2.4 1.5 3.7 1.5s2.7-.5 3.7-1.5L32.6 23c1.5.6 3.1 1 4.6 1 2.5 0 4.9-.9 6.6-2.6 3-3 4.1-8.5 2.4-13l-7.7 7.7L32 9.6l7.7-7.7c-1.3-.6-2.8-.8-4.3-.8z"
            fill="currentColor"
            class="text-blue-600"
        />
        <g>
            <path
                d="M14.5 43.2c-.7 0-1.3-.5-1.4-.6l-8.8-8.8c-3.1-3.1-3.1-8.1 0-11.2l6.2-6.2C12 14.8 14 14 16.1 14c2.1 0 4.1.8 5.6 2.3l1.8 1.8-1.7 1.9 9.7 9.7c.6.6.9 1.2.9 1.7 0 .6-.3 1.4-1.1 2.1-.5.5-.9.7-1.4.7-1 0-1.9-1-2.3-1.4-.3-.3-.4-.5-.8-.5h-.3l-.2.2c-.2.2-.4.6.2 1.1.5.5 1.4 1.5-.4 3.3-.6.6-1.1.9-1.6.9-.9 0-1.7-.8-2.1-1.3-.3-.3-.4-.5-.8-.5h-.3l-.2.2c-.3.3-.3.7.2 1.1.3.3.7.7.7 1.3 0 .6-.4 1.2-1.2 1.9-.5.4-.9.6-1.3.6-.8 0-1.5-.9-1.8-1.4-.3-.3-.4-.6-.8-.6h-.3l-.4.4v.3c0 .3.2.4.3.6.2.2.4.4.4.7 0 .4-.3.8-.8 1.3s-1 .8-1.6.8z"
                fill="currentColor"
                class="text-blue-50"
            />
            <path
                d="M16.1 14.6c2 0 3.8.8 5.2 2.1l1.4 1.4-.9.9-.9 1 .9.9 9.2 9.2c.9.9 1.1 1.6-.2 2.9-.3.3-.7.5-1 .5-.7 0-1.5-.8-1.8-1.2-.3-.3-.6-.7-1.2-.7h-.5l-.3.4c-.1.1-.9.9.2 2 .5.5 1 1-.4 2.4-.3.3-.8.7-1.2.7-.6 0-1.3-.7-1.6-1.1-.3-.3-.6-.7-1.2-.7h-.5l-.4.4c-.1.1-.8.9.2 2 .3.3.5.6.5.9 0 .4-.4.9-1 1.5-.3.3-.6.5-.9.5-.5 0-1.1-.8-1.3-1.2-.3-.4-.6-.9-1.3-.9h-.5l-.4.4-.4.4v.5c0 .5.3.9.5 1l.2.2c0 .1-.1.4-.6.9s-.9.7-1.3.7c-.5 0-.9-.4-1-.4l-8.8-8.8c-1.4-1.4-2.1-3.2-2.1-5.2s.8-3.8 2.1-5.2l6.2-6.2c1.3-1.4 3.2-2.2 5.1-2.2m0-1.2c-2.2 0-4.4.8-6.1 2.5l-6.2 6.2c-3.3 3.3-3.3 8.8 0 12.1l8.8 8.8c.3.3 1 .8 1.8.8.6 0 1.4-.3 2.2-1.1 2-2 .2-2.9.3-3 .2 0 1.1 2 2.6 2 .5 0 1.1-.2 1.7-.8 2.9-2.7.2-4.2.3-4.4.2 0 1.2 1.8 2.8 1.8.6 0 1.3-.3 2.1-1 2.8-2.8.1-4.2.2-4.4.2 0 1.4 1.9 3 1.9.6 0 1.2-.2 1.9-.9 1.9-1.9 1.4-3.4.2-4.7l-9-9.2 1.8-1.8-2.3-2.3c-1.7-1.7-3.9-2.5-6.1-2.5z"
                fill="currentColor"
                class="text-blue-600"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        small: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
