<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
    >
        <g stroke-width="2" transform="translate(0, 0)">
            <polyline
                data-cap="butt"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-miterlimit="10"
                points="18,5 11,1 4,5 "
                stroke-linejoin="miter"
                stroke-linecap="butt"
            ></polyline>
            <polyline
                data-cap="butt"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-miterlimit="10"
                points="12,19 1,19 1,5 21,5
	21,9.536 "
                stroke-linejoin="miter"
                stroke-linecap="butt"
            ></polyline>
            <line
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
                stroke-miterlimit="10"
                x1="5"
                y1="10"
                x2="12"
                y2="10"
                stroke-linejoin="miter"
            ></line>
            <line
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
                stroke-miterlimit="10"
                x1="5"
                y1="14"
                x2="9"
                y2="14"
                stroke-linejoin="miter"
            ></line>
            <polyline
                data-cap="butt"
                data-color="color-2"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-miterlimit="10"
                points="22,15.646 22,22
	19,21 16,22 16,15.646 "
                stroke-linejoin="miter"
                stroke-linecap="butt"
            ></polyline>
            <circle
                data-color="color-2"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="square"
                stroke-miterlimit="10"
                cx="19"
                cy="13"
                r="4"
                stroke-linejoin="miter"
            ></circle>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
