<template>
    <HomeLayout>
        <Head>
            <title>Job-Portal | Warnemünder Bau GmbH</title>
        </Head>

        <Categories :categories="categories" />

        <Projects></Projects>

        <Team></Team>

        <CTA></CTA>
    </HomeLayout>
</template>

<script>
export default {
    props: {
        categories: {
            type: Array,
        },
    },
};
</script>
